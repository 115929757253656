import React, { useState, useEffect } from 'react'
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import reactStringReplace from 'react-string-replace'
import { pulse, rubberBand } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { Link } from "react-router-dom";
import DataHooks from './DataHooks'
import Header from '../Header'

const Dashboard = () => {


  const bounceInAnimation = keyframes`${pulse}`;
  const rubberBandAnimation = keyframes`${rubberBand}`;
  const BouncyDiv = styled.div`animation: 0.3s ${bounceInAnimation};`;
  const RubberBandnDiv = styled.div`animation: 1s ${rubberBandAnimation};`;

  const [pageNumber, setPageNumber] = useState('6207')

  const { games, loading, firstData } = DataHooks(pageNumber)

  function handleOnDocumentBottom() {
    setPageNumber(firstData)
  }

  useEffect(() => {
  }, [])

  return (
    <div>
      <Header page="Anasayfa" />
      <BottomScrollListener onBottom={handleOnDocumentBottom} />
      <div className="container">
        {
          games.map(game => {
            return (
              <BouncyDiv key={game.id} style={{ backgroundImage: `url(${game.thumb})` }} onClick={() => {
                window.location.href = `/Game/${game.gameid}/${reactStringReplace(game.title.toString().toLowerCase(), ' ', (match, i) => ('_'))}.html`;
                return null;
              }}>
                <Link to={`/Game/${game.gameid}/${reactStringReplace(game.title.toString().toLowerCase(), ' ', (match, i) => ('_'))}.html`}>
                  <RubberBandnDiv>{game.title}</RubberBandnDiv>
                </Link>
              </BouncyDiv>
            )
          })
        }
      </div>
      <div>{loading && 'Loading...'}</div>
    </div>
  )
}

export default Dashboard
