import React from 'react'

const Footer = () => {
    return (
        <div onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className="footer">
            <a href="https://oyuntu.com" target="_top">Oyuntu</a> | <a href="https://bilbul.net" target="_blank">Bilmeceler</a> |  <a href="https://funtoma.com" target="_blank">Online Games</a> | <a href="https://zar-salla.web.app/" target="_blank">Zar At</a>
        </div>
    )
}

export default Footer
