import { useState, useEffect } from 'react';
import firebase from '../../firebase'

const useGame = ({ key }) => {

  const [games, setGames] = useState([]);

  useEffect(() => {

    const fetchData = async () => {
      const unsubscribe = await firebase
        .firestore()
        .collection('games')
        .where('gameid', '==', key)
        .limit(1)
        .onSnapshot((snapshot) => {
          const newGame = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))
          setGames(newGame)
        })
      return () => unsubscribe()
    }

    fetchData();

  }, [key]);

  return games

}

export default useGame
