import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage'
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyAjFg4p2Y5cR758ihmX7EDGvRn3r4tt-HA",
    authDomain: "oyuntu-com.firebaseapp.com",
    projectId: "oyuntu-com",
    storageBucket: "oyuntu-com.appspot.com",
    messagingSenderId: "972456759540",
    appId: "1:972456759540:web:8b036d6e4c6bbefbc1cea1",
    measurementId: "G-BXVZ4Z30D0"
};

firebase.initializeApp(config);

const storage = firebase.storage()

firebase.firestore().settings({ timestampsInSnapshots: true });

export {
    storage, firebase as default
}