import { useEffect, useState } from 'react'
import firebase from '../../firebase'

const DataHooks = (pageNumber) => {

    const [loading, setLoading] = useState(true)
    const [games, setGames] = useState([])
    const [firstData, setFirstData] = useState(pageNumber)

    useEffect(() => {
        setLoading(true)

        const fetchData = async () => {
            let limits = 9;
            let unsubscribe;
            try {
                unsubscribe = await firebase
                    .firestore()
                    .collection('games')
                    .orderBy('gameid', 'desc')
                    .startAfter(firstData)
                    .limit(limits)
                    .onSnapshot((snapshot) => {
                        const newGames = snapshot.docs.map((doc) => (
                            {
                                id: doc.id,
                                ...doc.data()
                            }
                        ))
                        let lenghtNew = newGames.length - 1;
                        if (lenghtNew > 0) {
                            setFirstData(newGames[lenghtNew].gameid)
                            setGames(prevGames => {
                                return [...new Set([...prevGames, ...newGames])]
                            })
                        }

                    });
            } catch (error) {
                unsubscribe = () => {
                    setGames(games)
                    setFirstData(firstData)
                }
            }
            setLoading(false);
            return () => unsubscribe();
        };
        fetchData();
    }, [pageNumber]);

    return { loading, games, firstData }
}

export default DataHooks