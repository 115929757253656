import React from 'react'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components';
import { bounceIn, bounceInDown, rubberBand } from 'react-animations';
import OyuntuLogo from './oyuntu.png'

const Navigation = () => {

    const bounceInAnimation = keyframes`${bounceIn}`;
    const bounceInDownAnimation = keyframes`${bounceInDown}`;
    const rubberBandAnimation = keyframes`${rubberBand}`;

    const BouncyDiv = styled.div`animation: 2s ${bounceInAnimation};`;
    const BouncInDiv = styled.div`animation: 1s ${bounceInDownAnimation};`;
    const RubberBandnDiv = styled.div`animation: 1s ${rubberBandAnimation};`;

    return (
        <div>
            <div className="parent">
                <BouncyDiv className="navbar">
                    <BouncInDiv className="logo">
                        <Link to="/">
                            <img
                                alt="Oyuntu"
                                src={OyuntuLogo}
                                height="50"
                            />
                        </Link>
                    </BouncInDiv>
                    <div className="menu">
                        <ul>
                            <RubberBandnDiv><li><Link to="/">Oyuntu</Link></li></RubberBandnDiv>
                            <RubberBandnDiv><li><Link to="/Games/Arcade">Arcade Games</Link></li></RubberBandnDiv>
                            <RubberBandnDiv><li><Link to="/Games/Puzzle">Puzzle Games</Link></li></RubberBandnDiv>
                            <RubberBandnDiv><li><Link to="/Games/Race">Race Games</Link></li></RubberBandnDiv>
                        </ul>
                    </div>
                </BouncyDiv>
            </div>
        </div>
    )
}

export default Navigation
