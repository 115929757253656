import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import './App.css'
import Dashboard from './components/Dashboard'
import Categories from './components/Categories'
import Game from './components/Game'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import { consoleLogo } from './components/Console'

const App = () => {

  useEffect(() => {
    consoleLogo()
  }, [])

  return (
    <Router>
      <Helmet>
        <style>
          {':root {--bgcolor: #371464;} body {background-color: #371464;animation: animatedBackground 1000s linear infinite;background-image: url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' viewBox=\'0 0 40 40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'white\' fill-opacity=\'0.03\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M0 40L40 0H20L0 20M40 40V20L20 40\'/%3E%3C/g%3E%3C/svg%3E");} @keyframes animatedBackground {from {background-position: 0 0;} to {background-position: -10000px 0;}}'}
        </style>
      </Helmet>
      <Navigation />
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/Games/:name" component={Categories} />
        <Route exact path="/Game/:key/:name.html" component={Game} />
        <Route exact path='*' component={Dashboard} />
      </Switch>
      <Footer />
    </Router>
  )
}

export default App
