import React from 'react'
import { useParams } from "react-router-dom";
import useGame from './useGame'
import { Link } from 'react-router-dom'
import Header from '../Header'
import reactStringReplace from 'react-string-replace'

const Game = () => {

    let { key } = useParams();

    const games = useGame({ key });

    return (
        <div>
            <div className="gamecontainer">
                {
                    games.map((game) =>
                        <div key={game.gameid}>
                            <Header page="Game" newTitle={game.title} newDesc={game.description} />
                            <div>
                                <h2><Link to={`/Game/${reactStringReplace(game.title.toString().toLowerCase(), ' ', (match, i) => ('_'))}-${game.gameid}`}>{game.title}</Link></h2>
                                <div>{game.description}</div>
                                <div><b>Game Play :</b> {game.instructions}</div>
                            </div>

                            <div className="resp-container">
                                <iframe class="resp-iframe" src={game.url} gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Game
