import React from 'react'
import { Helmet } from "react-helmet-async";

const Header = (props) => {
  let title = null
  let desc = null

  switch (props.page) {
    case "Anasayfa":
      title = <title>Oyuntu - Kızma Birader, Zeka Oyunları, Yarışmalar, Bulmacalar</title>;
      desc = <meta name="description" content="Kızma Birader, Zeka Oyunları, Yarışmalar, Bulmacalar. En güzel oyunlar bir arada. Online oyunlar." />;
      break;
    case "Category":
      title = <title>{props.newTitle} Oyunları - Oyuntu , En Güzel Oyunlar</title>;
      desc = <meta name="description" content="Kızma Birader, Zeka Oyunları, Yarışmalar, Bulmacalar. En güzel oyunlar bir arada. Online oyunlar." />;
      break;
    case "Game":
      title = <title>{props.newTitle} Oyna - {props.newTitle} Oyunu - Oyuntu</title>;
      desc = <meta name="description" content={props.newDesc} />;
      break;
    default:
      title = <title>Oyuntu - Kızma Birader, Zeka Oyunları, Yarışmalar, Bulmacalar</title>;
      desc = <meta name="description" content="Kızma Birader, Zeka Oyunları, Yarışmalar, Bulmacalar. En güzel oyunlar bir arada. Online oyunlar." />;
  }

  return (
    <Helmet>
      {title}
      {desc}
    </Helmet>
  )
}

export default Header
